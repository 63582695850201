import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const CustomLink = ({ children, to, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <Link to={to} {...other}>
        {children}
      </Link>
    );
  }
  return (
    <OutboundLink href={to} {...other} rel='noreferrer noopener'>
      {children}
    </OutboundLink>
  );
};

export default CustomLink;
