import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import { getCurrentLangKey } from 'ptz-i18n';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import favicon from '../assets/img/favicon.ico';
import i18next from '../components/I18next';
import theme from '../utils/Theme';
import store from '../store';

import CanonicalLink from '../components/CanonicalLink';
import AlternativeLinks from '../components/AlternativeLinks';
import Api from '../utils/api';
import Gtm from '../components/Gtm';
import GoogleAnalytics from '../components/GoogleAnalytics';
import FreshChatComponent from '../components/FreshChatComponent';

class Layout extends React.Component {
  constructor(props) {
    super(props);

    const meta = props.data.site.siteMetadata;

    this.api = new Api({
      gatsbyUrl: meta.gatsbyUrl,
      pythonUrl: meta.pythonUrl,
      i18n: i18next,
    });

    this.state = {};
  }

  static getDerivedStateFromProps(nextProps) {
    const { data, location } = nextProps;

    const url = location.pathname;
    const { defaultLanguageKey, languages } = data.site.siteMetadata.languages;
    const langKey = getCurrentLangKey(languages, defaultLanguageKey, url);

    i18next.changeLanguage(langKey);

    return null;
  }

  render() {
    const { data, children, location } = this.props;

    const url = location.pathname;
    const { defaultLanguageKey, languages } = data.site.siteMetadata.languages;
    const { gtmId, gaTid } = data.site.siteMetadata;
    const langKey = getCurrentLangKey(languages, defaultLanguageKey, url);
    const freshChat = data.site.siteMetadata.freshChat;
    const freshChatToken = freshChat.token;
    const freshChatBackgroundColor = freshChat.backgroundColor;
    const freshChatForegroundColor = freshChat.foregroundColor;
    const brandName = data.site.siteMetadata.brand.name;
    const freshChatSiteId = data.site.siteMetadata.brand.nameCom.toLowerCase();
    const pythonUrl = data.site.siteMetadata.pythonUrl;

    return (
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <ThemeProvider theme={theme.anonineTheme}>
            <div className={`lang-${langKey} app`}>
              <Helmet>
                <html lang={langKey} />
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <link rel="shortcut icon" href="https://anonine.com/favicon.ico" type="image/x-icon" />
                <link rel='prefetch' type='img/ico' href={favicon} />
                <link rel='preload' crossOrigin='crossorigin' href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700" as="font" />
                <body onunload="" />
              </Helmet>
              <CanonicalLink api={this.api} path={url} />
              <AlternativeLinks
                api={this.api}
                path={url}
                langs={languages}
                defaultLang={defaultLanguageKey}
              />
              {children}
              <div>
                <Gtm gtmId={gtmId} />
                <GoogleAnalytics gaTid={gaTid} />
              </div>
              <div>
                <FreshChatComponent
                  brandName={brandName}
                  siteId={freshChatSiteId}
                  token={freshChatToken}
                  backgroundColor={freshChatBackgroundColor}
                  foregroundColor={freshChatForegroundColor}
                  pythonUrl={pythonUrl}
                />
              </div>
            </div>
          </ThemeProvider>
        </I18nextProvider>
      </Provider>
    );
  }
}

const pageQuery = graphql`
  query Layout {
    site {
      siteMetadata {
        title
        languages {
          defaultLanguageKey
          languages
        }
        gatsbyUrl
        pythonUrl
        gtmId
        gaTid
        freshChat {
          token
          backgroundColor
          foregroundColor
        }
        brand {
          name
          nameCom
        }
      }
    }
  }
`;

export default ({ location, ...props }) => (
  <StaticQuery
    query={pageQuery}
    render={data => (
      <Layout data={data} location={location} {...props} />
    )}
  />
);
