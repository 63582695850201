import React from 'react';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import Link from './Link';
import LinkList from './LinkList';
import LanguageSwitcher from './LanguageSwitcher';
import appStoreImg from '../assets/img/appstore.svg';
import appGooglePlayStore from '../assets/img/playstore.svg';
import chromeStoreImg from '../assets/img/chrome-store-light-img.svg';
import facebookImg from '../assets/img/facebook.svg';
import twitterImg from '../assets/img/twitter.svg';
import Api from '../utils/api';
import { media } from '../utils/styled-utils';

import { Title } from './LinkListStyled';
import { Copyright } from './PageFooterStyled';

const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.color.dark};

    .wrapper {
        margin: 0 auto;
        max-width: 1232px;
    }
`;

const Footer = styled.div`
    padding: 60px 0 40px;
    margin: 0 64px;

    display: grid;
    display: -ms-grid;
    -ms-grid-columns: 2fr 2fr 2fr 2fr 2fr;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
    grid-template-areas:
     'product security company vpn-apps language'
     'product security company vpn-apps apps'
     'product security company vpn-apps extension'
     'copyright copyright copyright vpn-apps follow';
     grid-row-gap: 25px;

     ${media.less1024} {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas:
       'product'
       'security'
       'company'
       'vpn-apps'
       'apps'
       'extension'
       'language'
       'follow'
       'copyright';
      grid-row-gap: 8px;
      padding: 72px 24px 32px;
      margin: 0;
    }

    .footer__product {
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      -ms-grid-column: 1;
      grid-area: product;

      ${media.less1024} {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }
    }

    .footer__security {
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      -ms-grid-column: 2;
      grid-area: security;

      ${media.less1024} {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
      }
    }

    .footer__company {
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      -ms-grid-column: 3;
      grid-area: company;

      ${media.less1024} {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
      }
    }

    .footer__language {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
      grid-area: language;

      ${media.less1024} {
        -ms-grid-row: 7;
        -ms-grid-column: 1;
      }
    }

    .footer__vpn-apps {
      -ms-grid-row: 1;
      -ms-grid-row-span: 3;
      -ms-grid-column: 4;
      grid-area: vpn-apps;

      ${media.less1024} {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
      }
    }

    .footer__apps {
      -ms-grid-row: 2;
      -ms-grid-column: 5;
      grid-area: apps;

      ${media.less1024} {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
      }

      ul {
        display: flex;
      }
    }

    .footer__extension {
      -ms-grid-row: 3;
      -ms-grid-column: 5;
      grid-area: extension;

      ${media.less1024} {
        -ms-grid-row: 6;
        -ms-grid-column: 1;
      }

      ul {
        display: flex;
      }

      ${media.less1024} {
        padding-bottom: 16px;
      }
    }

    .footer__copyright {
      -ms-grid-row: 4;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      grid-area: copyright;
      display: flex;
      align-items: flex-end;

      ${media.less1024} {
        -ms-grid-row: 9;
        -ms-grid-column: 1;
      }
    }

    .footer__follow {
      -ms-grid-row: 4;
      -ms-grid-column: 5;
      grid-area: follow;

      ${media.less1024} {
        -ms-grid-row: 8;
        -ms-grid-column: 1;
      }

      a {
        margin-right: 16px;
      }

      a:last-child {
        margin-right: 0;
      }

      ${media.less1024} {
        padding-bottom: 24px;

        h3::after {
          display: none;
        }
      }
    }
`;

class PageFooter extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });

    this.state = {
      year: '',
      opened: '',
      openedItem: '',
    };

    this.onItemClick = this.onItemClick.bind(this);
  }

  componentWillMount() {
    this.setState({ year: (new Date()).getFullYear() });
  }

  onItemClick(e) {
    const linkName = e.target.dataset.toggle;
    const { openedItem } = this.state;

    this.setState(s => ({ s, ...{ openedItem: linkName === openedItem ? '' : linkName } }));
  }

  render() {
    const { t, location, brand, affiliateUrl } = this.props;
    const { year, openedItem } = this.state;

    return (
      <Wrapper>
        <div className='wrapper'>
          <Footer>
            <LinkList
              className='footer__product'
              title={t('layout_footer:product')}
              onClick={this.onItemClick}
              isOpened={openedItem === 'product'}
              titleKey='product'
              links={[
                { name: t('layout_footer:service'), url: this.api.serviceUrl() },
                { name: t('layout_footer:network'), url: this.api.networkUrl() },
                { name: t('layout_header:help'), url: this.api.helpUrl() },
                { name: t('layout_header:wireguard'), url: this.api.wireguardUrl() },
                { name: t('layout_header:affiliate'), url: affiliateUrl }
              ]}
            />
            <LinkList
              className='footer__security'
              title={t('layout_footer:security')}
              onClick={this.onItemClick}
              isOpened={openedItem === 'security'}
              titleKey='security'
              links={[
                { name: t('layout_footer:terms_of_service'), url: this.api.termsOfServiceUrl() },
                { name: t('layout_footer:privacy_policy'), url: this.api.privacyPolicyUrl() }
              ]}
            />
            <LinkList
              className='footer__company'
              title={t('layout_footer:company')}
              isOpened={openedItem === 'company'}
              onClick={this.onItemClick}
              titleKey='company'
              links={[
                { name: t('layout_footer:blog'), url: this.api.blogUrl() },
                { name: t('layout_footer:about_us'), url: this.api.aboutUsUrl() },
                { name: t('layout_footer:contact_us'), url: this.api.contactUrl() }
              ]}
            />
            <LinkList
              className='footer__vpn-apps'
              title={t('layout_footer:vpn_apps')}
              isOpened={openedItem === 'apps'}
              onClick={this.onItemClick}
              titleKey='apps'
              links={[
                { name: t('layout_footer:app_win'), url: this.api.applicationWindowsUrl() },
                { name: t('layout_footer:app_mac'), url: this.api.applicationMacOsUrl() },
                { name: t('layout_footer:app_ios'), url: this.api.applicationIosUrl() },
                { name: t('layout_footer:app_android'), url: this.api.applicationAndroidUrl() },
                { name: t('layout_footer:app_linux'), url: this.api.applicationLinuxUrl() },
                { name: t('layout_footer:app_router'), url: this.api.applicationRouterUrl() },
                { name: t('layout_footer:app_extension'), url: this.api.applicationExtensionUrl() }
              ]}
            />
            <div className='footer__language'>
              <LanguageSwitcher
                location={location}
                languages={[{ id: 'en', title: 'English' }, { id: 'sv', title: 'Svenska' }]}
              />
            </div>
            <div className='footer__apps'>
              <LinkList
                className='width-100 mobile'
                title={t('layout_footer:mobile_apps')}
                isOpened={openedItem === 'mobile_apps'}
                onClick={this.onItemClick}
                titleKey='mobile_apps'
                target='_blank'
                links={[
                  {
                    name: t('layout_footer:download_app_appstore'),
                    url: this.api.applicationIosAppStoreUrl(),
                    imgSrc: appStoreImg,
                    alt: t('layout_footer:download_app_appstore')
                  },
                  {
                    name: t('layout_footer:download_app_googleplay'),
                    url: this.api.applicationAndroidPlayStoreUrl(),
                    imgSrc: appGooglePlayStore,
                    alt: t('layout_footer:download_app_googleplay')
                  }
                ]}
              />
            </div>
            <div className='footer__extension'>
              <LinkList
                className='width-100 extension'
                title={t('layout_footer:chrome_extension')}
                isOpened={openedItem === 'chrome_extension'}
                onClick={this.onItemClick}
                titleKey='chrome_extension'
                target='_blank'
                links={[
                  {
                    name: t('layout_footer:download_app_appstore'),
                    url: this.api.applicationChromeStoreUrl(),
                    imgSrc: chromeStoreImg,
                    alt: t('layout_footer:download_app_appstore')
                  }
                ]}
              />
            </div>
            <div className='footer__follow'>
              <Title>
                {t('layout_footer:follow_us').toUpperCase()}
              </Title>
              <section>
                <Link target='_blank' to={brand.facebook} title={t('layout_footer:subscribe_facebook')}>
                  <img src={facebookImg} alt={t('layout_footer:subscribe_facebook')} />
                </Link>
                <Link target='_blank' to={brand.twitter} title={t('layout_footer:follow_twitter')}>
                  <img src={twitterImg} alt={t('layout_footer:follow_twitter')} />
                </Link>
              </section>
            </div>
            <div className='footer__copyright'>
              <Copyright>
                <Link to={this.api.indexUrl()}>
                  {/*{`${brand.nameCom}`}*/}
                  Edelino Commerce Inc.
                </Link>
                <span>
                  {` \u00A9 ${year} ${t('layout_footer:all_rights_reserved')}`}
                </span>
                <br/>
                <span>
                  {t('layout_footer:wireguard_copyright')}
                </span>
              </Copyright>
            </div>
          </Footer>
        </div>
      </Wrapper>
    );
  }
}

export default withNamespaces()(PageFooter);
