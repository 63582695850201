import styled, { css } from 'styled-components';
import GatsbyLink from './Link';
import arrowDown from '../assets/img/round-keyboard-arrow-down-24-px-1.svg';
import { media } from '../utils/styled-utils';

export const LinkListStyled = styled.section`
  ${media.less1024} {
    width: 100%;
    padding-bottom: ${({ isOpened }) => (isOpened ? '16px' : '0')};
    border-bottom: 1px solid #898da3;
    
    &.extension {
      padding-bottom: 0;
      margin-bottom: 24px;
    }
    
    & .footer__stores {
      padding-bottom: 0 !important;
      ul {
        display: flex;
      }
    
      h3 {
        padding-top: 0 !important;
      }
    }
  }
`;

export const Title = styled.h3`
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #898da3;
    margin-bottom: 12px;
    
    ${media.less1024} {
        width: 100%; 
        position: relative;
        padding-bottom: 0;
        margin-bottom: 8px;
        
        &::after {
            position: absolute;
            right: 0;
            content: '';
            height: 24px;
            width: 24px;
            background: transparent url(${arrowDown}) no-repeat center center;
            ${({ isOpened }) => isOpened && css`
                transform: rotate(180deg);
            `}
        }
    }
`;

export const List = styled.ul`
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    
    ${media.less1024} {
        display: ${({ isOpened }) => (isOpened ? 'block' : 'none !important')};
    }
`;

export const Item = styled.li`
    padding: 0;
    margin: 0 0 10px 0;
    font-weight: 600;
    
    &.img-container {
        margin-right: 16px;   
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    ${media.less1024} {
        padding-bottom: 0;
        
        &.img-container {
            margin-right: 8px;   
            
            &:last-child {
                margin-right: 0;
            }
            
            & img {
                width: 100%;
            }
            
            margin-bottom: 8px;
        }
    
    }
`;

export const Link = styled(GatsbyLink)`
    display: block;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: 0.2px;
    color: #ffffff;

    &:hover {
        color: ${({ theme }) => theme.color.darkPeriwinkle};
        text-decoration: none;
    }
`;
