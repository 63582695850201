import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import en from '../data/locales/en/translation.json';
import sv from '../data/locales/sv/translation.json';

import en_core from '../data/locales/en/core.json';
import sv_core from '../data/locales/sv/core.json';

import en_backend from '../data/locales/en/backend.json';
import sv_backend from '../data/locales/sv/backend.json';

import en_page_login from '../data/locales/en/page_login.json';
import sv_page_login from '../data/locales/sv/page_login.json';

import en_page_home from '../data/locales/en/page_home.json';
import sv_page_home from '../data/locales/sv/page_home.json';

import en_page_forgot_password from '../data/locales/en/page_forgot_password.json';
import sv_page_forgot_password from '../data/locales/sv/page_forgot_password.json';

import en_page_update_password from '../data/locales/en/page_update_password.json';
import sv_page_update_password from '../data/locales/sv/page_update_password.json';

import en_layout_header from '../data/locales/en/layout_header.json';
import sv_layout_header from '../data/locales/sv/layout_header.json';

import en_layout_footer from '../data/locales/en/layout_footer.json';
import sv_layout_footer from '../data/locales/sv/layout_footer.json';

import en_page_network from '../data/locales/en/page_network.json';
import sv_page_network from '../data/locales/sv/page_network.json';

import en_page_terms_of_service from '../data/locales/en/page_terms_of_service.json';
import sv_page_terms_of_service from '../data/locales/sv/page_terms_of_service.json';

import en_auto_plurals from '../data/locales/en/auto_plurals.json';
import sv_auto_plurals from '../data/locales/sv/auto_plurals.json';

import en_page_about from '../data/locales/en/page_about.json';
import sv_page_about from '../data/locales/sv/page_about.json';

import en_page_privacy_policy from '../data/locales/en/page_privacy_policy.json';
import sv_page_privacy_policy from '../data/locales/sv/page_privacy_policy.json';

import en_page_applications from '../data/locales/en/page_applications.json';
import sv_page_applications from '../data/locales/sv/page_applications.json';

import en_page_service from '../data/locales/en/page_service.json';
import sv_page_service from '../data/locales/sv/page_service.json';

import en_page_contact_us from '../data/locales/en/page_contact_us.json';
import sv_page_contact_us from '../data/locales/sv/page_contact_us.json';

import en_page_wireguard from '../data/locales/en/page_wireguard.json';
import sv_page_wireguard from '../data/locales/sv/page_wireguard.json';

class RussianPlurals {
  static isOne(count) {
    return (
      ((count % 100) < 10 || (count % 100) > 20)
      && (count % 10 === 1)
    );
  }

  static isFew(count) {
    return (
      ((count % 100) < 10 || (count % 100) > 20)
      && (count % 10 >= 2)
      && (count % 10) <= 4
    );
  }

  static isMany(count) {
    return !RussianPlurals.isOne(count) && !RussianPlurals.isFew(count);
  }
}

i18next.use(intervalPlural);

i18next.init({
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false,
    defaultVariables: {
      brand: 'Anonine',
      brandCom: 'Anonine.com',
      supportEmail: 'support@anonine.com',
    },
  },
  fallbackLng: 'en',
  resources: {
    en: {
      translation: en,
      core: en_core,
      backend: en_backend,
      page_login: en_page_login,
      page_forgot_password: en_page_forgot_password,
      page_update_password: en_page_update_password,
      page_home: en_page_home,
      page_about: en_page_about,
      page_terms_of_service: en_page_terms_of_service,
      page_network: en_page_network,
      layout_header: en_layout_header,
      layout_footer: en_layout_footer,
      auto_plurals: en_auto_plurals,
      page_privacy_policy: en_page_privacy_policy,
      page_applications: en_page_applications,
      page_service: en_page_service,
      page_contact_us: en_page_contact_us,
      page_wireguard: en_page_wireguard,
    },
    sv: {
      translation: sv,
      core: sv_core,
      backend: sv_backend,
      page_login: sv_page_login,
      page_forgot_password: sv_page_forgot_password,
      page_update_password: sv_page_update_password,
      page_privacy_policy: sv_page_privacy_policy,
      page_home: sv_page_home,
      page_network: sv_page_network,
      page_about: sv_page_about,
      page_terms_of_service: sv_page_terms_of_service,
      layout_header: sv_layout_header,
      layout_footer: sv_layout_footer,
      auto_plurals: sv_auto_plurals,
      page_applications: sv_page_applications,
      page_service: sv_page_service,
      page_contact_us: sv_page_contact_us,
      page_wireguard: sv_page_wireguard,
    },
  },
  returnObjects: false,
  returnedObjectHandler(key, value, options) {
    // if not a plural object
    if (typeof value !== 'object') {
      return value;
    }

    // if not a number
    const count = options.count;
    if (parseInt(count, 10) !== count) {
      return value;
    }

    const lang = i18next.languages[0];
    return this.interpolate(
      this.extractPlural(key, value, lang, count),
      options,
    );
  },
  extractPlural(key, value, lang, count) {
    // if Russian
    if (lang === 'ru') {
      if (RussianPlurals.isOne(count)) {
        return value.one;
      }
      if (RussianPlurals.isFew(count)) {
        return value.few;
      }
      if (RussianPlurals.isMany(count)) {
        return value.many;
      }
      return value.other;
    }
    return count === 1 ? value.one : value.other;
  },
  interpolate(s, obj) {
    return s.replace(/{{([^{}]*)}}/g,
      (placeHolder, fieldName) => {
        const fieldValue = obj[fieldName];
        if (typeof fieldValue === 'string' || typeof fieldValue === 'number') {
          return fieldValue;
        }
        return placeHolder;
      });
  },
});

export default i18next;
