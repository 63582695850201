import styled from 'styled-components';

const PageContent = styled.div`
    margin-top: 58px;
    width: 100%;    
    @media (max-width: 479px) and (min-width: 0) {
      margin-top: 56px;
    }
`;

export default PageContent;
