import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media } from '../utils/styled-utils';
import mask from '../assets/img/mask.svg';

import Button from './Button';

type Props = {
  brand: string,
  orderUrl: string,
  text: string,
  buttonText: string,
  nomask: boolean,
  noBorder: boolean,
  noPadding: boolean,
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${({ noPadding }) => (noPadding ? '16px' : '152px 32px 140px')};

  ${media.less1024} {
    padding: 64px 16px;
    
    ${({ noPadding }) => noPadding && css`
      padding: 0;
    `}
  }
`;

const InnerWrapper = styled.div`
  max-width: 1232px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const InnerWrapperForText = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1024px;
  justify-content: center;
  align-items: center;
  padding: 48px 0;
  ${({ noBorder }) => !noBorder && css`
    border: 1px solid ${({ theme }) => theme.color.silver};
    border-left: none;
    border-right: none;
  `}

  ${media.tablet} {
    flex-wrap: wrap;
    padding: 32px 0;
  }

  ${media.mobile} {
    padding: 24px 0;
  }
`;

const Text = styled.span`
  color: ${({ theme }) => theme.color.dark};
  font-size: 40px;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 0.6px;
  padding-right: 32px;

  ${media.tablet} {
    font-size: 32px;
    text-align: center;
  }
  
  @media only screen and (max-width: 541px) {
    flex: 100%;
    padding-bottom: 24px;
    padding-right: 0;
  }

  ${media.mobile} {
    flex: 100%;
    font-size: 24px;
    padding-bottom: 16px;
    padding-right: 0;
  }

`;

const WrapperForMask = styled.div`
  display: ${({ nomask }) => (nomask ? 'none' : 'block')};
  padding-top: 90px;
  
  img {
    width: 134px;
    display: block;
    margin: 0 auto;
    ${media.tablet} {
      width: 100px;
    }
    
    ${media.mobile} {
      width: 64px;
    }
  }

  ${media.tablet} {
    padding-top: 64px;
  }

  ${media.mobile} {
    padding-top: 32px;
  }

`;

const NotAMemberComponent = ({ brand, orderUrl, text, buttonText, nomask, noBorder, noPadding }: Props) => (
  <Wrapper className='member-wrapper' noPadding={noPadding}>
    <InnerWrapper>
      <div>
        <InnerWrapperForText className='member-inner-wrapper' noBorder={noBorder}>
          <Text>
            {text}
          </Text>
          <Button to={orderUrl}>
            {buttonText}
          </Button>
        </InnerWrapperForText>
      </div>
      <WrapperForMask nomask={nomask}>
        <img src={mask} alt={brand} />
      </WrapperForMask>
    </InnerWrapper>
  </Wrapper>
);

NotAMemberComponent.propTypes = {
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  orderUrl: PropTypes.string.isRequired,
};

export default NotAMemberComponent;
