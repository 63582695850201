/* eslint-disable class-methods-use-this */

export default class {
  constructor({
    pythonUrl,
    gatsbyUrl,
    helpDeskUrl = 'https://help.anonine.com',
    i18n,
  }) {
    this.pythonUrl = pythonUrl;
    this.gatsbyUrl = gatsbyUrl;
    this.helpDeskUrl = helpDeskUrl;
    this.i18n = i18n;
  }

  generateConfig({ data = {}, method = 'POST', uri }) {
    const url = this.pythonApiUrlFactory(uri);

    const config = {
      crossDomain: true,
      withCredentials: true,
      method,
    };

    return method === 'GET'
      ? { ...config, url, method }
      : { ...config, url, method, data };
  }

  loginConfig({ data = {} }) {
    return this.generateConfig({
      uri: '/www/login',
      data,
    });
  }

  statusConfig() {
    const date = new Date();

    return this.generateConfig({
      uri: `/www/login-status?ts=${date.getTime()}`,
      method: 'GET',
    });
  }

  contactUsConfig({ data = {} }) {
    return this.generateConfig({
      uri: '/www/contact-us',
      method: 'POST',
      data,
    });
  }

  updatePasswordConfig({ data = {} }) {
    return this.generateConfig({
      uri: '/www/update-password',
      data,
    });
  }

  forgotPasswordConfig({ data = {} }) {
    return this.generateConfig({
      uri: '/www/forgot-password',
      data,
    });
  }

  logoutConfig() {
    return this.generateConfig({
      uri: '/www/logout',
    });
  }

  userData() {
    const date = new Date();

    return this.generateConfig({
      uri: `/www/whois?ts=${date.getTime()}`,
      method: 'GET',
    });
  }

  plansConfig() {
    return this.generateConfig({
      uri: '/www/plans',
      method: 'GET'
    });
  }

  pythonApiUrlFactory(path) {
    return this.i18n.language === 'en'
      ? `${this.pythonUrl}${path}`
      : `${this.pythonUrl}/${this.i18n.language}${path}`;
  }

  pythonWebUrlFactory(path, helpDesk = false) {
    return helpDesk
      ? `${this.helpDeskUrl}${path}`
      : `${this.pythonUrl}/${this.i18n.language}${path}`;
  }

  gatsbyUrlFactory(path, ext = false) {
    const lang = this.i18n.language;
    const locale = lang === 'en' ? '' : `/${lang}`;
    if (ext) {
      return `${this.gatsbyUrl}${locale}${path}`;
    }
    return `${locale}${path}`;
  }

  logoutUrl() {
    return this.pythonApiUrlFactory('/logout');
  }

  accountUrl() {
    return this.pythonWebUrlFactory('/account');
  }

  orderUrl() {
    return this.pythonWebUrlFactory('/order/purchase');
  }

  indexUrl() {
    return this.gatsbyUrlFactory('/');
  }

  loginUrlAbs() {
    return this.gatsbyUrlFactory('/login', true);
  }

  loginUrl() {
    return this.gatsbyUrlFactory('/login');
  }

  forgotPasswordUrl() {
    return this.gatsbyUrlFactory('/forgot-password');
  }

  forgotPasswordUrlAbs() {
    return this.gatsbyUrlFactory('/forgot-password', true);
  }

  serviceUrl() {
    return this.gatsbyUrlFactory('/service');
  }

  networkUrl() {
    return this.gatsbyUrlFactory('/network');
  }

  supportUrl() {
    return this.gatsbyUrlFactory('/support');
  }

  termsOfServiceUrl() {
    return this.gatsbyUrlFactory('/terms-of-service');
  }

  privacyPolicyUrl() {
    return this.gatsbyUrlFactory('/privacy-policy');
  }

  aboutUsUrl() {
    return this.gatsbyUrlFactory('/about');
  }

  contactUrl() {
    return this.gatsbyUrlFactory('/contact');
  }

  updatePasswordUrl() {
    return this.gatsbyUrlFactory('/update-password', true);
  }

  installationUrl() {
    return this.gatsbyUrlFactory('/installation');
  }

  applicationsUrl() {
    return this.gatsbyUrlFactory('/applications');
  }

  applicationIosUrl() {
    return this.gatsbyUrlFactory('/applications/ios');
  }

  applicationAndroidUrl() {
    return this.gatsbyUrlFactory('/applications/android');
  }

  applicationMacOsUrl() {
    return this.gatsbyUrlFactory('/applications/macos');
  }

  applicationWindowsUrl() {
    return this.gatsbyUrlFactory('/applications/windows');
  }

  applicationLinuxUrl() {
    return this.gatsbyUrlFactory('/applications/linux');
  }

  applicationRouterUrl() {
    return this.gatsbyUrlFactory('/applications/router');
  }

  applicationExtensionUrl() {
    return this.gatsbyUrlFactory('/applications/extension');
  }

  serverStatusUrl() {
    return this.pythonApiUrlFactory('/www/server-status');
  }

  wireguardUrl() {
    return this.gatsbyUrlFactory('/wireguard');
  }

  applicationMacOsGuideUrl() {
    return this.pythonWebUrlFactory(
      '/support/solutions/articles/5000792229-macos-guides',
      true,
    );
  }

  applicationWindowsGuideUrl() {
    return this.pythonWebUrlFactory(
      '/support/solutions/articles/5000792198-windows-guides',
      true,
    );
  }

  applicationWindowsAppUrl() {
    return 'https://anonine.com/download-client?platform=win';
  }

  macOSDownloadUrl() {
    return 'https://anonine.com/download-macos-client';
  }

  applicationLinuxGuideUrl() {
    return this.pythonWebUrlFactory(
      '/support/solutions/articles/5000792228-linux-guides',
      true,
    );
  }

  applicationRouterGuideUrl() {
    return this.pythonWebUrlFactory(
      '/support/solutions/articles/5000792232-routers-guides',
      true,
    );
  }

  applicationAndroidApkUrl() {
    return 'https://anonine.com/downloads/anonine.zip';
  }

  applicationAndroidGuideUrl() {
    return this.pythonWebUrlFactory(
      '/support/solutions/articles/5000792231-android-guides',
      true,
    );
  }

  applicationIosGuideUrl() {
    return this.pythonWebUrlFactory(
      '/support/solutions/articles/5000792230-ios-guides',
      true,
    );
  }

  applicationIosAppStoreUrl() {
    return 'https://itunes.apple.com/ca/app/anonine/id969103391?mt=8';
  }

  applicationAndroidPlayStoreUrl() {
    return 'https://play.google.com/store/apps/details?id=com.oneonone.anonine';
  }

  applicationChromeStoreUrl() {
    return 'https://chrome.google.com/webstore/detail/anonine-proxy/ghlhknlbgiefmfnbpjcgikhhgoobocen/related';
  }

  faqUrl() {
    return this.pythonWebUrlFactory(
      '/support/solutions/folders/5000251169',
      true,
    );
  }

  blogUrl() {
    return 'https://anonine.com/blog';
  }

  helpUrl() {
    return this.pythonWebUrlFactory(
      '/support/solutions',
      true,
    );
  }

  getAbsUrl(path) {
    return `${this.gatsbyUrl}${path}`;
  }
}
