import React from 'react';
import cl from 'classnames';
import './Tooltip.scss';
import Api from "../../utils/api";
import { withNamespaces } from 'react-i18next';
import { Link } from "gatsby";
import winImg from "../../assets/img/applications/logo-windows.svg";
import macImg from "../../assets/img/applications/mac-os-logo.svg";
import linuxImg from "../../assets/img/applications/logo-linux.svg";
import iosImg from "../../assets/img/applications/logo-apple.svg";
import androidImg from "../../assets/img/applications/logo-android.svg";
import routerImg from "../../assets/img/applications/logo-router.svg";


const TooltipWrapper = props => (
  <div
    className={ cl({
      'custom-tooltip bottom': true,
      'dropdown-menu': props.isDropdownMenu,
      'show': props.isShowTooltip
    }) }
  >
    <div className='tooltip-arrow'/>
    <div className='tooltip-content'>
      { props.content }
    </div>
  </div>
);

class TooltipComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const emptySymbol = '–';
    const { t, isProtected, isShowTooltip, userData } = this.props;
    const content = (
      <>
        <div className='tooltip-inner'>
          <div className='tooltip-item'>
            <div className='tooltip-label'>{ t('layout_header:your_ip') }</div>
            <div className='tooltip-description'>{ userData.ip || emptySymbol }</div>
          </div>
          <div className='tooltip-item'>
            <div className='tooltip-label'>{ t('layout_header:country') }</div>
            <div className='tooltip-description'>{ userData.country || emptySymbol }</div>
          </div>
          <div className='tooltip-item'>
            <div className='tooltip-label'>{ t('layout_header:isp') }</div>
            <div className='tooltip-description'>{ userData.isp || emptySymbol }</div>
          </div>
        </div>
        <div
          className={ cl({
            'tooltip-footer': true,
            'protected-status': isProtected,
            'unprotected-status': !isProtected
          }) }>
          <div className='tooltip-description'>
            { isProtected
              ? t('layout_header:connected_str')
              : t('layout_header:not_connected_str') }
            <br/>
            { t('layout_header:our_vpn_str') }
          </div>
        </div>
      </>
    );

    return <TooltipWrapper
      content={ content }
      isShowTooltip={ isShowTooltip }
    />;
  }
}


export const getAppItemList = (api, t) => (
  [
    {
      platform: t('layout_header:all_platforms'),
      imgSrc: winImg,
      link: api.applicationsUrl()
    },
    {
      platform: t('layout_header:image_alt_windows'),
      imgSrc: winImg, link: api.applicationWindowsUrl() },

    {
      platform: t('layout_header:image_alt_macos'),
      imgSrc: macImg,
      link: api.applicationMacOsUrl()
    },
    {
      platform: t('layout_header:image_alt_linux'),
      imgSrc: linuxImg,
      link: api.applicationLinuxUrl()
    },
    {
      platform: t('layout_header:image_alt_ios'),
      imgSrc: iosImg,
      link: api.applicationIosUrl()
    },
    {
      platform: t('layout_header:image_alt_android'),
      imgSrc: androidImg,
      link: api.applicationAndroidUrl()
    },
    {
      platform: t('layout_header:image_alt_routers'),
      imgSrc: routerImg,
      link: api.applicationRouterUrl()
    },
    {
      platform: t('layout_header:image_alt_extension'),
      imgSrc: routerImg,
      link: api.applicationExtensionUrl()
    },
  ]
);


class DropdownMenuComponent extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
  }

  getTranslations() {
    const { t } = this.props;

    return {
      applicationList: getAppItemList(this.api, t),
      reviews: [
        { text: t('layout_header:rev_1'), author: t('layout_header:anonymous') },
        { text: t('layout_header:rev_2'), author: t('layout_header:anonymous') },
      ],
    };
  }

  render() {
    const { isShowTooltip } = this.props;
    const { applicationList } = this.getTranslations();
    const content = (
      <div className="dropdown-content">
        { applicationList.map(({ platform, link }) => (
          <Link
            className="dropdown-item"
            key={ `application${platform}` }
            to={ link }
          >
            <span>
              { platform }
            </span>
          </Link>
        )) }
      </div>
    );

    return <TooltipWrapper
      content={ content }
      isShowTooltip={ isShowTooltip }
      isDropdownMenu={ true }
    />;
  }
}

const Tooltip = withNamespaces()(TooltipComponent);
const DropdownMenu = withNamespaces()(DropdownMenuComponent);

export { Tooltip, DropdownMenu };
