import { css } from 'styled-components';

const sizes = {
  mobile: { max: 479 },
  tablet: { max: 839 },
  tabletMid: { min: 480, max: 569 },
  tabletExact: { min: 480, max: 839 },
  tabletLandscape: { min: 840, max: 1280 },
  less1024: { max: 1024 },
  more1024: { min: 1025 },
};

const mediaGenerator = ({ max = null, min = null }) => css`
  @media only screen
  ${max && `and (max-width: ${max / 16}em)`}
  ${min && `and (min-width: ${min / 16}em)`}
`;

export const media = {
  mobile: mediaGenerator(sizes.mobile),
  tablet: mediaGenerator(sizes.tablet),
  tabletMid: mediaGenerator(sizes.tabletMid),
  tabletExact: mediaGenerator(sizes.tabletExact),
  tabletLandscape: mediaGenerator(sizes.tabletLandscape),
  less1024: mediaGenerator(sizes.less1024),
  more1024: mediaGenerator(sizes.more1024),
};

export function truncate(width) {
  return `
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}
