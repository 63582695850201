import React from 'react';
import { Link, Item, List, Title, LinkListStyled } from './LinkListStyled';

class LinkList extends React.Component {
  render() {
    const { links, title, titleKey, isOpened, onClick = false, className } = this.props;

    return (
      <LinkListStyled isOpened={isOpened} className={className}>
        <Title
          isOpened={isOpened}
          onClick={onClick}
          data-test={`footer-title-${titleKey}`}
          data-toggle={titleKey}
        >
          {title.toUpperCase()}
        </Title>
        <List isOpened={isOpened}>
          {links.map((linkElement, idx) => (
            <Item key={`${linkElement.titleKey}-${idx}`} className={linkElement.imgSrc && 'img-container'}>
              <Link
                to={linkElement.url}
                data-test={`footer-link-${titleKey}-${linkElement.name}`}
              >
                {typeof linkElement.imgSrc === 'undefined'
                  ? linkElement.name
                  : <img src={linkElement.imgSrc} alt={linkElement.alt ? linkElement.alt : ''} />
                }
              </Link>
            </Item>
          ))}
        </List>
      </LinkListStyled>
    );
  }
}

export default LinkList;
