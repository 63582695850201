import React from 'react';
import { Helmet } from 'react-helmet';

const GoogleAnalytics = ({ gaTid = '' }) => (
  <Helmet>
    <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTid}`} />
    <script>
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gaTid}');
    `}
    </script>
  </Helmet>
);

export default GoogleAnalytics;
