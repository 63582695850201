import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { get, isEmpty } from 'lodash';
import cl from 'classnames';
import { withNamespaces } from 'react-i18next';
import { Tooltip } from '../components/Tooltip/Tooltip';
import Api from '../utils/api';
import logoImg from '../assets/img/anonine.svg';
import loader from '../assets/img/protected_loader.svg';
import { Navbar, Nav, NavDropdown, Button, Container } from 'react-bootstrap';
import { getAppItemList } from "./Tooltip/Tooltip";
import 'bootstrap/dist/css/bootstrap.min.css';
import './PageHeader.scss';


const Logo = styled.img`
  display: inline-block;
  margin: 0;
  padding: 0;

  @media only screen and (max-width: 768px) {
    height: 26px;
  }

  @media only screen and (max-width: 879px) and (min-width: 480px) {
    height: 30px;
  }
  
  @media (max-width: 1024px) {
    padding-left: 16px;
  }
`;

const Loader = () => (
  <img src={ loader } />
);

const ProtectionCircle = () => (
  <div className='circle__icon'/>
);

class PageHeader extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      pythonUrl: props.pythonUrl,
      gatsbyUrl: props.gatsbyUrl,
      i18n: props.i18n,
    });
    this.state = {
      isAuthenticated: false,
      failedLoginsCount: 0,
      isMultipleLoginFail: false,
      isScrolled: false,
      isProtected: null,
      isShowTooltip: false,
      userData: null,
      failedLoadData: false,
      isMenuOpen: false,
    };
    this.scrollHandler = this.scrollHandler.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      document.addEventListener('scroll', this.scrollHandler);
      this.checkUserLogin();
      this.getUserData();
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      const app = document.getElementsByClassName('app')[0];
      document.removeEventListener('scroll', this.scrollHandler);
    }
  }

  scrollHandler() {
    const { isScrolled } = this.state;

    if (typeof window !== 'undefined') {
      const currentIsScrolled = window.pageYOffset !== 0;

      if (isScrolled !== currentIsScrolled) {
        this.setState({ isScrolled: currentIsScrolled });
      }
    }
  }

  checkUserLogin() {
    const config = this.api.statusConfig();

    axios(config)
      .then(response => {
        const isAuthenticated = get(response, 'data.authenticated');
        const failedLoginsCount = get(response, 'data.failed_logins_count');
        const isMultipleLoginFail = failedLoginsCount > 1;

        this.setState({
          isAuthenticated,
          failedLoginsCount,
          isMultipleLoginFail,
        });

        if (isAuthenticated) {
          this.setState({ isAuthenticated: true });
        }
      })
      .catch();
  }

  getUserData() {
    const userDataRequest = this.api.userData();

    axios(userDataRequest).then(response => {
      this.setState({
        isProtected: get(response.data, 'ip_is_in_our_network'),
        userData: response.data
      });
    })
    .catch(() => {
      this.setState({
        failedLoadData: true,
        userData: {}
      });
    });
  }

  toggleTooltip() {
    this.setState(state => ({ isShowTooltip: !state.isShowTooltip }));
  }

  toggleMenu() {
    this.setState(state => ({ isMenuOpen: !state.isMenuOpen }));
  }

  render() {
    const { t, brand, isMain } = this.props;
    const {
      isAuthenticated, isScrolled, isProtected, isShowTooltip, userData,
      failedLoadData, isMenuOpen
    } = this.state;
    const isEmptyUserData = isEmpty(userData);

    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        bg={ isMain  ? "light" : "dark" }
        variant={ isMain ? "light" : "dark" }
        fixed="top"
        id="anonine-navbar"
        className={cl({
          "scrolled": isScrolled,
          "menu-open": isMenuOpen,
        })}
      >
        <Container id="header-container">
          <Navbar.Brand href={this.api.indexUrl()}>
            <Logo src={logoImg} alt={brand.name} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={this.toggleMenu} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
            <Nav>
              <Nav.Link href={this.api.serviceUrl()} data-test='service'>
                {t('layout_header:service')}
              </Nav.Link>
              <Nav.Link href={this.api.networkUrl()} data-test='network'>
                {t('layout_header:network')}
              </Nav.Link>
              <Nav.Link href={this.api.orderUrl()} data-test='pricing'>
                {t('layout_header:pricing')}
              </Nav.Link>
              <NavDropdown title={t('layout_header:apps')} id="collasible-nav-dropdown">
                { getAppItemList(this.api, t).map((appItem, index) => (
                  <NavDropdown.Item href={appItem.link} key={`app-item-${index}`}>
                    {appItem.platform}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <Nav.Link href={this.api.helpUrl()} data-test='help'>
                {t('layout_header:help')}
              </Nav.Link>
              <Nav.Item
                id="protection-panel"
                className={ cl({
                  header__protect: true,
                  header__protected: isProtected,
                  header__unprotected: !isProtected,
                  header__load_protect: !userData,
                  header__fail_load: failedLoadData
                }) }
              >
                <div
                  className='protect-link-block'
                  onMouseOver={ this.toggleTooltip }
                  onMouseOut={ this.toggleTooltip }
                >
                  { userData ? <ProtectionCircle/> : <Loader/> }
                  <a className='protect-link'>
                    { !isEmptyUserData || failedLoadData
                      ? isProtected
                        ? t('layout_header:protected')
                        : t('layout_header:unprotected')
                      : <div/>
                    }
                  </a>
                </div>
                { !isEmptyUserData &&
                  <Tooltip
                    isShowTooltip={ isShowTooltip }
                    isProtected={ isProtected }
                    userData={ userData }
                  />
                }
              </Nav.Item>
              {isAuthenticated ? (
                <Button href={this.api.accountUrl()} data-test='sign-in'>
                  {t('layout_header:sign_in')}
                </Button>
              ) : (
                <Button href={this.api.loginUrl()} data-test='sign-in'>
                  {t('layout_header:sign_in')}
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default withNamespaces()(PageHeader);
