import * as types from '../Actions/Types';

const initialState = {
  servers: null,
  filter: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
  case types.SERVER_LIST_UPDATED: {
    payload.sort((s1, s2) => s1.location.title.localeCompare(s2.location.title));

    return {
      ...state,
      servers: payload,
    };
  }

  case types.FILTER_UPDATED:
    return {
      ...state,
      filter: payload,
    };

  case types.START_PING: {
    const newServers = state.servers.map(server => {
      if (server.alias === payload) {
        return {
          ...server,
          pingStarted: true,
        };
      }
      return server;
    });

    return {
      ...state,
      servers: newServers,
    };
  }

  case types.STOP_PING: {
    const newServers = state.servers.map(server => {
      if (server.alias === payload) {
        return {
          ...server,
          pingStarted: false,
        };
      }
      return server;
    });

    return {
      ...state,
      servers: newServers,
    };
  }

  case types.PING_RESPONSE: {
    const newServers = state.servers.map(server => {
      if (server.alias === payload.alias) {
        const ping = !server.ping || payload.responseTime < server.ping
          ? payload.responseTime : server.ping;

        return {
          ...server,
          ping,
        };
      }
      return server;
    });

    return {
      ...state,
      servers: newServers,
    };
  }

  case types.PING_RESET: {
    const newServers = state.servers.map(server => {
      if (server.alias === payload) {
        return {
          ...server,
          pingStarted: false,
          ping: null,
        };
      }
      return server;
    });

    return {
      ...state,
      servers: newServers,
    };
  }

  case types.SORT_SERVERS_BY_LOAD: {
    const newServers = [...state.servers];
    newServers.sort((s1, s2) => s1.load - s2.load);

    return {
      ...state,
      servers: newServers,
    };
  }

  case types.SORT_SERVERS_BY_LOCATION: {
    const newServers = [...state.servers];
    newServers.sort((s1, s2) => s1.location.title.localeCompare(s2.location.title));

    return {
      ...state,
      servers: newServers,
    };
  }

  case types.SORT_SERVERS_BY_ALIAS: {
    const newServers = [...state.servers];
    newServers.sort((s1, s2) => s1.alias.localeCompare(s2.alias));

    return {
      ...state,
      servers: newServers,
    };
  }

  case types.SORT_SERVERS_BY_UPTIME: {
    const newServers = [...state.servers];
    newServers.sort((s1, s2) => s2.uptime - s1.uptime);

    return {
      ...state,
      servers: newServers,
    };
  }

  default:
    return state;
  }
};
