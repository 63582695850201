import React from 'react';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { navigate } from 'gatsby';
import selectArrow from '../assets/img/round-arrow-drop-down-24-px-1.svg';
import { switchLang } from '../utils/locales';
import { media } from '../utils/styled-utils';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const userLangCookie = 'user_lang';

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;

  ${media.less1024} {
    width: 100%;
    padding-bottom: 24px;
  }
`;

const ToggleArrow = styled.div`
  display: inline-block;
  height: 100%;
  position: relative;

  &::before {
    position: absolute;
    content: url(${selectArrow});
    color: #ffffff;
    font-size: 8px;
    left: -16px;
    top: -10px;
  }
`;

const Flag = styled.div`
  position: absolute;
  height: 20px;
  width: 24px;
  top: 14px;
  left: 12px;
  background-size: contain;
  background-repeat: no-repeat;
`;

const Select = styled.select`
  padding: 8px 24px 8px 48px;
  line-height: 1.6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(255, 255, 255, 0.08);
  border-color: transparent;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  text-indent: 1px;
  text-overflow: '';

  &::-ms-expand {
    display: none;
  }

  ${media.less1024} {
    width: 100%;
  }
`;

const StyledOption = styled.option`
  color: ${({ active }) => (active ? 'gray' : '#000')};
`;

const Label = styled.label`
  width: 100%;
  margin-bottom: 0;
`;

const Option = ({ lang, t, activeLanguage }) => (
  <StyledOption
    value={lang.id}
    data-test={`lang-${lang.id}`}
    active={activeLanguage === lang.id}
  >
    {t(lang.title)}
  </StyledOption>
);

class LanguageSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    this.setState({ activeLanguage: this.props.i18n.language });
  }

  onChange(e) {
    const { location } = this.props;
    const activeLanguage = e.target.value;
    const url = switchLang(location.pathname, activeLanguage);
    this.setUserLang(activeLanguage);
    this.setState({ activeLanguage });
    navigate(url);
  }

  setUserLang(lang) {
    cookies.set(userLangCookie, lang, { path: '/' });
  }

  render() {
    const { languages, t } = this.props;
    const { activeLanguage } = this.state;

    return (
      <SelectWrapper>
        <Flag className={`flag-icon-css flag-icon-${activeLanguage}`} />
        <Label htmlFor='lang-select' aria-label={t('page_home:select_language')}>
          <Select
            id='lang-select'
            data-test='lang-select'
            ref={select => (this.select = select)}
            onChange={e => this.onChange(e)}
            value={activeLanguage}
          >
            {languages.map(lang => (
              <Option
                activeLanguage={activeLanguage}
                key={lang.id}
                lang={lang}
                t={t}
              />
            ))}
          </Select>
        </Label>
        <ToggleArrow />
      </SelectWrapper>
    );
  }
}

export default withNamespaces()(LanguageSwitcher);
