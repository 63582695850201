export const addLang = (url, lang) => `/${lang}${url}`;

export const replaceLang = (url, lang) => url.replace(/^\/[a-zA-Z]{2}\//, `/${lang}/`);

export const removeLang = url => url.replace(/^\/[a-zA-Z]{2}\//, '/');

export const hasLangPrefix = url => url.search(/^\/[a-zA-Z]{2}\//) !== -1;

export const switchLang = (url, lang) => {
  if (!hasLangPrefix(url)) {
    if (lang === 'en') {
      return url;
    }
    return addLang(url, lang);
  }

  if (lang === 'en') {
    return removeLang(url);
  }

  return replaceLang(url, lang);
};
