import React from 'react';
import { Helmet } from 'react-helmet';


const CanonicalLink = ({ api, path }) => (
  <Helmet>
    <link rel='canonical' href={api.getAbsUrl(path)} />
  </Helmet>
);


export default CanonicalLink;
