import React from 'react';
import FreshChat from 'react-freshchat';
import logo from '../assets/img/freshchat-logo.png';


const FreshChatComponent = ({
  brandName, siteId, token, backgroundColor, foregroundColor, pythonUrl
}) => (
  <div>
    {
      token && typeof window !== 'undefined'
      ? <FreshChat
          token={token}
          siteId={siteId}
          onInit={widget => {
            widget.setConfig({
              headerProperty: {
                appName: brandName,
                backgroundColor: backgroundColor,
                foregroundColor: foregroundColor,
                appLogo: pythonUrl + '/static/img/freshchat-logo.png',
              }
            })
          }}
        />
      : <div/>
    }
  </div>
);

export default FreshChatComponent;
