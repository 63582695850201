export const SERVER_LIST_UPDATED = 'servers/SERVER_LIST_UPDATED';
export const FILTER_UPDATED = 'servers/FILTER_UPDATED';
export const PING_RESPONSE = 'servers/PING_RESPONSE';
export const PING_SERVER = 'servers/PING_SERVER';
export const PING_RESET = 'servers/PING_RESET';
export const SORT_SERVERS_BY_LOAD = 'servers/SORT_SERVERS_BY_LOAD';
export const SORT_SERVERS_BY_LOCATION = 'servers/SORT_SERVERS_BY_LOCATION';
export const SORT_SERVERS_BY_ALIAS = 'servers/SORT_SERVERS_BY_ALIAS';
export const SORT_SERVERS_BY_UPTIME = 'servers/SORT_SERVERS_BY_UPTIME';
export const START_PING = 'servers/START_PING';
export const STOP_PING = 'servers/STOP_PING';
