import React from 'react';
import { Helmet } from 'react-helmet';
import { switchLang } from '../utils/locales';


const AlternativeLinks = ({ api, path, langs, defaultLang }) => (
  <Helmet>
    {langs.map(lang => <link rel='alternate' hrefLang={lang} href={api.getAbsUrl(switchLang(path, lang))} key={lang} />)}
    <link rel='alternate' hrefLang='x-default' href={api.getAbsUrl(switchLang(path, defaultLang))} />
  </Helmet>
);


export default AlternativeLinks;
