import styled from 'styled-components';

export const Copyright = styled.span`
    font-size: 18px;
    color: #fff;   
    font-weight: normal;
    
    a {
        color: #fff;   
        text-decoration: none;
    }
    
    @media only screen and (max-width: 767px) {
        line-height: 1.33;
        letter-spacing: 0.2px;
        color: #898da3;   
    a {
        color: #898da3;   
    }
`;
