export default {
  anonineTheme: {
    color: {
      coolGrey: '#9698a3',
      silver: '#d7d9e0',
      dark: '#1c1d29',
      charcoalGrey: '#373b52',
      slateGrey: '#4e5166',
      darkMint: '#55c962',
      steel: '#787b8f',
      algaeGreen: '#29cc75',
      darkPeriwinkle: '#6a77cc',
      fadedRed: '#d03a53',
    },
  },
};
