import React from 'react';
import styled from 'styled-components';
import Link from './Link';
import { media } from '../utils/styled-utils';

const BtnWrapper = styled.div`
  display: inline-block;
`;

const Btn = styled(Link)`
  display: inline-block;
  box-sizing: border-box;
  padding: 16px 24px;
  color: #ffffff !important;
  border-radius: 4px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.color.algaeGreen};
  box-shadow: 0 2px 4px -1px rgba(55, 59, 82, 0.4);
  transition: background-color ease-in-out 0.15s;
  letter-spacing: 0.5px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 4px 12px -2px rgba(55, 59, 82, 0.4);
    background-color: #33d67f;
    text-decoration: none;
  }

  &:active {
    background-color: #1be077;
    box-shadow: none;
  }

  ${media.mobile} {
    font-size: 16px;
  }
`;

const DarkBtn = styled(Btn)`
  background-color: ${({ theme }) => theme.color.charcoalGrey};

  &:hover {
    background-color: #3d415c;
  }

  &:active {
    background-color: #3d4366;
  }
`;

const Button = ({ dark, ...props }) => (
  <BtnWrapper>
    {dark ? <DarkBtn {...props} /> : <Btn {...props} />}
  </BtnWrapper>
);

export default Button;
