// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from "react-i18next";

import anonineMain from "../assets/img/mainPage/anonine_main.svg";


class PageHeaderTitle extends React.Component {
  render() {
    const {brandName, title} = this.props;
    const name = `${title} | ${brandName} VPN`;

    return (
      <Helmet title={name}>
        <meta name='description' content={name}/>
        <meta name="title" content={name}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://anonine.com/"/>
        <meta property="og:title" content={name}/>
        <meta
          property="og:description"
          content="High Speed VPN Provider, No Logging"
        />
        <meta property="og:image" content={anonineMain}/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://anonine.com/"/>
        <meta property="twitter:title" content={name}/>
        <meta
          property="twitter:description"
          content="High Speed VPN Provider, No Logging"
        />
        <meta property="twitter:image" content={anonineMain}/>
      </Helmet>
    )
  }
}


export default withNamespaces()(PageHeaderTitle);
